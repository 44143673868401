import React, { useState } from "react"
import Moment from 'react-moment'
import styled from "styled-components"
import { Link, navigate } from "gatsby"
import { ModalProvider, BaseModalBackground } from "styled-react-modal"
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { TestimonialsSection } from "../../styledComponents/testimonials"
import { analyticBeginCheckout } from "../googleAnalytics"

import {
  EventFigureCaption,
  EventDate,
  EventLocation,
  StyledEventSingleCard,
  StyledEventSingleFigure,
  StyledEventSingleCalendarCard,
  StyledEventSingleHeader,
  StyledEventSingleExcerpt,
  StyledEventBookingButtons,
  StyledEventBookingPassed,
  StyledPaymentIcons
} from "./StyledPrimaryEventCard"
import { StyledModal } from "../../styledComponents/modal"
import {
  StyledRegisterNowButton,
  StyledDepositButton,
  StyledCloseModalButton,
  StyledLinkButton
} from "../../styledComponents/button"
import Logo from "../../components/logo"
import Icon from "../../components/icon"
import parse from "html-react-parser"
import IconPaymentCards from "../iconPaymentCards"
import FormEventRegisterDates from "../formEventRegisterDates"
import WorkshopImage from "../../components/workshop-image"
function FancyModalButton(data) {
  const [isOpen, setIsOpen] = useState(false);
  const [opacity, setOpacity] = useState(0);

  function toggleModal(e) {
    setOpacity(0);
    setIsOpen(!isOpen);
  }

  function afterOpen() {
    setTimeout(() => {
      setOpacity(1);
    }, 100);
  }

  function beforeClose() {
    return new Promise((resolve) => {
      setOpacity(0);
      setTimeout(resolve, 300);
    });
  }

  var workshopDate = new Date(data.date)
  var daysBefore21 = new Date(workshopDate)
  daysBefore21.setDate(daysBefore21.getDate() - 20)
  var today = new Date()
  let showEarlyBird = false
  if (today <= daysBefore21) {
    showEarlyBird = true
  } else {
    showEarlyBird = false
  }

  const ebPrice = data.earlyBird - 50
  const fPrice = data.fullPrice - 50
  const afterDepositTotal = showEarlyBird ? ebPrice : fPrice
  let currencyCode
  if (data.currency === '£') {
    currencyCode = 'GBP'
  } else {
    currencyCode = 'USD'
  }
  return (
    <>
      <StyledDepositButton onClick={toggleModal}>Deposit, payment plan or repeat student?</StyledDepositButton>
      <StyledModal
        isOpen={isOpen}
        afterOpen={afterOpen}
        beforeClose={beforeClose}
        onBackgroundClick={toggleModal}
        onEscapeKeydown={toggleModal}
        opacity={opacity}
        backgroundProps={{ opacity }}
        className="deposit-modal"
      >
        <StyledCloseModalButton onClick={toggleModal}>
          <Icon name="cross" />
        </StyledCloseModalButton>
        <h2><Logo /></h2>
        <h3>Deposit, payment plan or repeat student?</h3>
        <TestimonialsSection itemProp="articleBody" noPadding>
          <Tabs className="x">
            <TabList>
              <Tab>Deposit</Tab>
              <Tab>Payment Plan</Tab>
              <Tab>Repeat student</Tab>
            </TabList>
            <TabPanel>
              <p>Pay {data.currency}50 today to secure your space on the <strong>{data.event}</strong> workshop starting on <strong><Moment format="dddd, MMMM, Do">{data.date}</Moment></strong>.</p>
              <p>Then pay the final {data.currency}{afterDepositTotal} one week before the workshop.</p>
              <StyledRegisterNowButton
                onClick={() => {
                  navigate(`${data.stripeDepositURL}`);
                  analyticBeginCheckout(
                    currencyCode,
                    '50',
                    data.productId,
                    data.event + ' - ' + data.date + ' - deposit',
                    '50'
                  );
                }
                }>Pay deposit</StyledRegisterNowButton>
            </TabPanel>
            <TabPanel>
              <p>To set up a monthly payment plan please <a className="nomargin" href={`mailto:tracy@karinagrant.co.uk?subject=Payment plan for ${data.date}`}>email Tracy</a> and she can set this up for you.</p>
            </TabPanel>
            <TabPanel>
              <p>If you have attended a <strong>{data.event}</strong>, the cost to repeat the workshop starting on <strong><Moment format="dddd, MMMM, Do">{data.date}</Moment></strong> is {data.currency}{data.repeatPrice}</p>
              <StyledRegisterNowButton
                onClick={() => {
                  navigate(`${data.stripeRepeatURL}`);
                  analyticBeginCheckout(
                    currencyCode,
                    data.repeatPrice,
                    data.productId,
                    data.event + ' - ' + data.date + ' - repeat',
                    data.repeatPrice
                  );
                }
                }>Register now</StyledRegisterNowButton>
            </TabPanel>
          </Tabs>
        </TestimonialsSection>
      </StyledModal>
    </>
  )
}

const FadingBackground = styled(BaseModalBackground)`
  opacity: ${(props) => props.opacity};
  transition: all 0.3s ease-in-out;
`;


const SingleEventCard = (props) => {
  const {
    hasEventDate,
    eventTitle,
    datetime,
    timeZone,
    currency,
    sendDataToParent,
    priceSale,
    price,
    excerpt,
    lastFewSpaces,
    // sendEventTypeToParent,
    // sendEventTitleToParent,
    eventType,
    eventLocation,
    eventId,
    stripeUrl,
    stripeUrlEarlyBird,
    stripeUrlDeposit,
    stripeUrlRepeat,
    lastFewDaysEarlyBird,
    repeatHalfPrice,
    locationaPathname
  } = props
  function handleDataFromChild(data) {
    sendDataToParent(data)
  }

  let showProfile
  let dateTimeWithTimeZone
  let showEarlyBird = false
  if (hasEventDate) {
    let input = props.datetime
    if (input) {
      let fields = input.split('_')
      var date = fields[0];
      var time = fields[1];
      var timeNoSec = time.slice(0, -3);
      var et = fields[2];
    }

    var workshopDate = new Date(date)
    var daysBefore21 = new Date(workshopDate)
    daysBefore21.setDate(daysBefore21.getDate() - 20)
    var today = new Date()
    today.setDate(today.getDate() - 1)
    var Difference_In_Time = daysBefore21.getTime() - today.getTime();
    var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

    if (today <= daysBefore21) {
      showEarlyBird = true
    } else {
      showEarlyBird = false
    }
    if (timeZone === 'GMT') {
      dateTimeWithTimeZone = datetime + '-00:00'
    } else {
      dateTimeWithTimeZone = datetime + '-01:00'
    }
    let currencyCode
    if (currency === '£') {
      currencyCode = 'GBP'
    } else {
      currencyCode = 'USD'
    }
  }
  return (
    <StyledEventSingleCard id="single-event">
      <span>
        <StyledEventSingleFigure>
          <WorkshopImage eventType={eventType} />
          {lastFewSpaces && <EventFigureCaption type={showProfile}><Icon name="notification" /> <span>{lastFewSpaces}</span> </EventFigureCaption>}
          {lastFewDaysEarlyBird && <EventFigureCaption type={showProfile}><Icon name="notification" /> <span>{lastFewDaysEarlyBird}</span> </EventFigureCaption>}
          {Difference_In_Days <= 14 && Difference_In_Days > 0 && eventType !== 'qt-energy-boost' &&
            <EventFigureCaption type={showProfile}>
              <Icon name="notification" />
              {Math.floor(Difference_In_Days) === 0 ? ' Earlybird: Last day to save' : <span>Earlybird: {Math.floor(Difference_In_Days)} {Math.floor(Difference_In_Days) === 1 ? 'day' : 'days'} left</span>}
            </EventFigureCaption>
          }
        </StyledEventSingleFigure>
        <StyledEventSingleHeader>
          {/* {workshopName ? parse(workshopName) : parse(title)} */}
          {eventTitle}
        </StyledEventSingleHeader>

        <StyledEventSingleExcerpt><p>{excerpt}</p></StyledEventSingleExcerpt>

        {hasEventDate ?
          <>
            <EventDate className="start-date-time" content={dateTimeWithTimeZone}>
              <strong>Starts on:</strong> <Moment format="dddd, MMMM, Do">{date}</Moment>
              {' '}{timeNoSec} {' '} {timeZone}
            </EventDate>
            <StyledEventSingleCalendarCard ariaHidden="true">
            <a href={locationaPathname +'?id=' +eventId}><Moment className="event-month" element="span" format="MMM">
          {date}</Moment></a>
              <Moment className="event-day" element="span" format="D">{date}</Moment>
            </StyledEventSingleCalendarCard>
            {eventLocation === 'Zoom' ?
              <EventLocation className="event-location"><strong>Location:</strong> <meta itemProp="url" content={'x'} />{'Online somewhere comfortable'}</EventLocation>
              : eventLocation === 'Occidental Costa Cancún' ?
                <EventLocation className="event-location 12">
                  <span className="hidden">{'locationUrl'}</span>
                  <strong>Location:</strong> {'Occidental Costa Cancún'}, <span><span>{'77500 Mexico'}</span>
                  </span>
                </EventLocation>
                : <EventLocation className="event-location 12">
                  <span className="hidden">{'locationUrl'}</span>
                  <strong>Location:</strong> {'Healing House'}, <span>
                    <span>{'Bounds Green'}</span>, <span>{'London'}</span>, <span>{'N22 8DH'}</span>
                  </span>
                </EventLocation>
            }

            <div className="event-price">
              {eventType === 'qt-becoming-more' ?
                <><strong>Ticket</strong>: <span>${price} USD</span></>
                : showEarlyBird && priceSale !== null ?
                  <>
                    <strong className="sale">Earlybird ticket: <span className="sale">{currency}{priceSale}</span></strong>
                    <strong>Ticket</strong>: <span>{currency}{props.price}</span><br />
                    <span className="daynotice">{'If booked 21 calendar days before the workshop takes place.'}</span>
                  </>
                  :
                  <><strong>Ticket</strong>: <span>{currency}{price}</span></>
              }
            </div>
            {today <= workshopDate ?
              <StyledEventBookingButtons>
                {
                  eventType === 'qt-becoming-more' ?
                    <>
                      <StyledLinkButton
                        className="read-more event"
                        href={stripeUrl}
                        target="_blank"
                        onClick={() => {
                          navigate(`${stripeUrl}`)
                        }
                        }>Book now <Icon name="external" /></StyledLinkButton>
                      <span className="daynotice"><strong>Note</strong>: This link will take you to the Quantum-Touch offical booking page.</span>
                    </>
                    : showEarlyBird ?
                      <>
                        <StyledRegisterNowButton
                          onClick={() => {
                            navigate(`${stripeUrlEarlyBird}`);
                            analyticBeginCheckout(
                              currency,
                              props.priceSale,
                              props.id,
                              props.eventTitle + ' - ' + date,
                              props.priceSale
                            );
                          }
                          }>Register now</StyledRegisterNowButton>
                      </>
                      :
                      <>
                        <StyledRegisterNowButton
                          onClick={() => {
                            navigate(`${stripeUrl}`);
                            analyticBeginCheckout(
                              currency,
                              props.price,
                              props.id,
                              props.eventTitle + ' - ' + date,
                              props.price
                            );
                          }}
                        >Register now {lastFewDaysEarlyBird != null && ' - limited spaces'}</StyledRegisterNowButton>
                      </>
                }

                {/* Repeat / Deposit link */}
                {eventType !== 'qt-energy-boost' && eventType !== 'qt-becoming-more' &&
                  <ModalProvider backgroundComponent={FadingBackground}>
                    <FancyModalButton
                      date={date}
                      event={eventTitle}
                      stripeDepositID={stripeUrlDeposit}
                      earlyBird={priceSale}
                      fullPrice={price}
                      repeatPrice={repeatHalfPrice}
                      currency={props.currency}
                      stripeDepositURL={stripeUrlDeposit}
                      stripeRepeatURL={stripeUrlRepeat}
                      productId={props.id}
                    />
                  </ModalProvider>
                }
                {eventType !== 'qt-becoming-more' &&
                  <StyledPaymentIcons>
                    <h4 style={{ 'display': 'none' }}>Payment cards accepted</h4>
                    <IconPaymentCards name="paymentlogos" />
                  </StyledPaymentIcons>
                }
                {/* <FormEventRegisterDates sendDataToParent={handleDataFromChild} /> */}
              </StyledEventBookingButtons>

              :
              <StyledEventBookingPassed>
                <p><strong>Note:</strong> This event has passed, <Link to="/events">please view all upcoming workshops &amp; events</Link>.</p>
              </StyledEventBookingPassed>
            }
          </>
          :
          <FormEventRegisterDates sendDataToParent={handleDataFromChild} />
        }

      </span>
    </StyledEventSingleCard>
  );
}

export default SingleEventCard
